<template>
  <div>
    <b-row class="match-height">
      <b-col md="12">
        <b-card title="Identity Verification Statistics">
          <b-card-body>
            <b-row>
              <b-col
                v-for="(item, key) in dashBoard"
                :key="key"
                md="3"
              >
                <b-row>
                  <b-avatar
                    :variant="`light-${item.color}`"
                    class="mr-1"
                    size="45"
                  >
                    <feather-icon
                      :icon="item.icon"
                      size="21"
                    />
                  </b-avatar>
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ $t(item.title) }}
                    </h2>
                    <span>{{ $t(item.description) }}</span>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <h1>{{ user }}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <AppTable
          :rows="biometricsInfo.document"
          :columns="biometricDocuments"
          :is-loading="isLoadingBiometricInfo"
          :is-pagination="false"
        >
          <template #prependFilters>
            <b-card-title>{{ $t('kyc.dashboard.document') }}</b-card-title>
          </template>
        </AppTable>
      </b-col>

      <b-col md="12">
        <AppTable
          :rows="biometricsInfo.face2face"
          :columns="biometricF2F"
          :is-loading="isLoadingBiometricInfo"
          :is-pagination="false"
        >
          <template #prependFilters>
            <b-card-title>{{ $t('kyc.dashboard.f2f') }}</b-card-title>
          </template>
        </AppTable>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAvatar, BCard, BCardBody, BCol, BRow, BCardTitle,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import AppTable from '@/components/AppTable.vue'
import { biometricDocuments, biometricF2F } from '@/views/apps/kyc/config/kycTableConfig'

export default {
  name: 'KYC',
  components: {
    AppTable,
    BAvatar,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BCardTitle,
  },
  data() {
    return {
      dashboardInfo: {
        total: 0,
        verified: 0,
        inProgress: 0,
        rejected: 0,
      },
      color: 'primary',

      biometricDocuments,
      biometricF2F,
      isLoadingBiometricInfo: false,
      biometricsInfo: {
        document: [],
        face2face: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
    }),
    dashBoard() {
      return [
        {
          color: 'primary',
          icon: 'UserIcon',
          title: this.$t(this.dashboardInfo.total),
          description: this.$t('kyc.dashboard.verificationApplications'),
        },
        {
          color: 'success',
          icon: 'CheckCircleIcon',
          title: this.$t(this.dashboardInfo.verified),
          description: this.$t('kyc.dashboard.verifiedApplications'),
        },
        {
          color: 'warning',
          icon: 'ClockIcon',
          title: this.$t(this.dashboardInfo.inProgress),
          description: this.$t('kyc.dashboard.verificationInProgress'),
        },
        {
          color: 'danger',
          icon: 'XCircleIcon',
          title: this.$t(this.dashboardInfo.rejected),
          description: this.$t('kyc.dashboard.rejectedApplications'),
        },
      ]
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchBiometricDashboard: 'kyc/fetchBiometricDashboard',
      fetchDashboardInfo: 'kyc/fetchDashboardInfo',
    }),
    initState() {
      this.getDashboardInfo()
      this.getDocuments()
    },
    async getDashboardInfo() {
      const { data } = await this.fetchDashboardInfo('kyc/fetchDashboardInfo')
      this.dashboardInfo = data
    },

    async getDocuments() {
      this.isLoadingBiometricInfo = true
      const { data } = await this.fetchBiometricDashboard('kyc/fetchDashboardInfo')
      this.biometricsInfo = data || this.biometricsInfo
      this.isLoadingBiometricInfo = false
    },
  },
}
</script>
